.history {
    background-color: var(--themeColor);
    color: white;
    padding: 7vw;
}

.history h2 {
    font-size: 3.8vw;
}

.history p {
    font-size: 1.3vw;
    text-align: justify;
}


.history-button {
    background-color: white;
    border: none;
    color: #000;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.3vw;
    margin: 4px 2px;
}

@media screen and (max-width: 900px) {

    .history h2 {
        font-size: 4.2vw;
    }

    .history p {
        font-size: 1.8vw;
    }

    .history-button {
        font-size: 1.8vw;
    }

}

@media screen and (max-width: 500px) {
    .history h2 {
        font-size: 5vw;
    }

    .history p {
        font-size: 3vw;
    }

    .history-button {
        font-size: 3vw;
    }
}