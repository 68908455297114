.home-nav-container {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--themeColor);
    position: relative;
    z-index: 10;
    margin: 0;
}

.home-nav {
    display: flex;
    list-style-type: none;
    width: 100%;
    justify-content: space-between;
    padding-top: 0.5rem;
    margin: 0;
    transition: left 0.3s ease-in-out;
    height: 5vh;
}

.home-nav li a {
    color: white;
    text-decoration: none;
    font-size: 1.2vw;
    padding: 0.5rem;
    transition: color 0.3s;
}

.home-nav li a:hover {
    color: #f0f0f0;
}

.home-nav-hamburger {
    display: none;
    cursor: pointer;
    padding: 1rem;
    z-index: 11;
}

@media screen and (max-width: 900px) {
    .home-nav-container {
        padding: 0 4vw;
    }

    .home-nav {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        top: 100%;
        left: -100%;
        height: 50vh;
        background: rgba(0, 0, 0, 0.9);
        width: 100%;
        transition: left 0.3s ease-in-out;
    }

    .home-nav.active {
        left: 0;
    }

    .home-nav-hamburger {
        display: block;
        color: white;
    }

    .home-nav li {
        width: 100%;
        padding: 0.8rem 1.5rem;
    }

    .home-nav li a {
        font-size: 1rem;
        width: 100%;
        display: block;
    }
}
