.side-nav-container {
    background-color: var(--themeColor);
    height: 100vh;
    width: 25vw;
    position: relative;
    z-index: 2;
}

.side-nav {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: absolute;
    top: 10rem;
    left: 0;
}

.side-nav li {
    list-style-type: none;
}

.side-nav li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.8vw;
}

.side-nav-logo {
    color: #fff;
    margin-right: 1rem;
}

.side-nav-hamburger {
    display: none;
    position: absolute;
    top: 3rem;
    left: 2rem;
    z-index: 2;
}

.side-nav li a:hover {
    color: #E4E3E3;
}

@media screen and (min-width:1600px) {
    .side-nav-logo {
        width: 2vw;
        height: auto;
    }
}

@media screen and (max-width: 900px) {

    .side-nav-container {
        left: -100%;
        transition: 0.3s;
    }

    .side-nav-container.active {
        left: 0;
    }

    .side-nav-hamburger {
        display: initial;
        position: fixed;
    }

}

@media screen and (max-width: 700px) {
    .side-nav li {
        display: flex;
        flex-direction: column;
    }

    .side-nav li a {
        font-size: 2.5vw;
    }
}

@media screen and (max-width: 450px) {
    .side-nav-container {
        width: 100vw;
    }

    .side-nav li {
        display: flex;
        flex-direction: row;
    }

    .side-nav li a {
        font-size: 4.5vw;
    }
}