.students-corner-body {
    background-color: white;
    padding: 7vw;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.corner-body-cards {
    background-color: #F5F5F5;
    padding: 1rem;
    display: flex;

}

.corner-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width:1600px) {

    .students-corner-body h2 {
        font-size: 3.8vw;
    }

    .students-corner-body p {
        font-size: 1.3vw;
    }
    
}