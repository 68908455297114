.section-3 {
    background-color: var(--themeColor);
    color: white;
    display: flex;
    gap: 3rem;
    padding: 7vw;
}

.section-3 h2 {
    font-size: 3.8vw;
}

.section-3 p {
    font-size: 1.3vw;
    text-align: justify;
}

@media screen and (max-width: 800px) {
    .section-3 {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
}

@media screen and (max-width: 900px) {

    .section-3 h2 {
        font-size: 4.2vw;
    }
    
    .section-3 p {
        font-size: 1.8vw;
    }
    
}

@media screen and (max-width: 500px) {

    .section-3 h2 {
        font-size: 5vw;
    }
    
    .section-3 p {
        font-size: 3vw;
    }
}

