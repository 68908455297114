.laboratory {
    display: flex;
    background-color: white;
    color: black;
    padding: 9vw;
}

.laboratory-body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 50%;
}

.laboratory-body h2 {
    font-size: 3.8vw;
}

.laboratory-body p {
    font-size: 1.3vw;
}

.laboratory-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.laboratory-img img {
    max-width: 80%;
    max-height: 80%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.lab-button {
    background-color: var(--themeColor);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5vw;
    margin: 4px 2px;
}

.lab-button:hover {
    background-color: #1d4b7c;
}

@media screen and (max-width: 1000px) {
    .laboratory {
        flex-direction: column;
    }

    .laboratory-body {
        width: 100%;
    }

    .laboratory-img {
        padding: 1rem;
        min-width: 80%;
    }

    .laboratory-body h2 {
        font-size: 4.1vw;
    }
    
    .laboratory-body p {
        font-size: 1.7vw;
    }

    .lab-button {
        font-size: 1.9vw;
    } 
}

@media screen and (max-width: 500px) {
    .laboratory-img img {
        max-width: 100%;
    }

    .laboratory-body h2 {
        font-size: 4.3vw;
    }
    
    .laboratory-body p {
        font-size: 2.5vw;
    }

    .lab-button {
        font-size: 2.6vw;
    } 
}