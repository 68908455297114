header {
    background-color: white;
    text-align: center;
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: "15vh";
    padding: 0 7vw;
}



.nav-toggle {
    display: none;
}

.nav-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
    display: block;
    background: black;
    height: 2px;
    width: 2em;
    border-radius: 2px;
    position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
    content: '';
    position: absolute;
}

.nav-toggle-label span::before {
    bottom: 7px;
}

.nav-toggle-label span::after {
    top: 7px;
}

nav {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background: white;
    width: 100%;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav li {
    margin-bottom: 1em;
    margin-left: 1em;
}

nav a {
    color: var(--themeColor);
    text-decoration: none;
    font-size: 3.2rem;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.nav-toggle:checked ~ nav {
    transform: scale(1, 1); 
}

.nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 900px) {
    .nav-toggle-label {
        display: none;
    }

    header {
        display: grid;
        grid-template-columns: 1fr auto minmax(100px, 4fr) 1fr;
    }

   

    .logo {
        grid-column: 1 / 2;
    }

    nav {
        all: unset;
        grid-column: 3 / -1;
        margin-right: 1em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    nav ul {
        display: flex;
        justify-content: flex-end;
    }

    nav li {
        margin-left: 3em;
        margin-bottom: 0;
    }

    nav a {
        opacity: 1;
        font-size: 1.5vw;
    }
}