.course-list {
    background-color: white;
    padding: 0 7vw;
    padding-bottom: 7vw;
}

.list-section {
    display: flex;
    gap: 1rem;
    padding: 1rem;
}

.list-section .list-section-radio {
    width: 52px;
    height: 20px;
    cursor: pointer;
}

.list-section-hamburger {
    display: none;
}

.list-group {
    padding: 5px 0;
}


@media screen and (min-width:1600px) {

    .list-section input {
        font-size: 1.3vw;
    }

    .list-group a {
        font-size: 1.3vw;
    }
    
}

@media screen and (max-width:900px) {
    .list-section {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: auto;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        left: -100%;
        transition: 0.3s;
        z-index: 1;
        padding-top: 2rem;
        padding-left: 1.5rem;
    }

    .list-section.active {
        left: 0;
    }

    .list-section-hamburger {
        display: initial;
        padding: 0rem;
        position: relative;
        z-index: 2;
    }

    .list-group {
        padding-top: 1rem;
    }

}