.message {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10%;
    width: 100%;
    padding: 7vw;
}

.contact-form {
    background-color: white;
    padding: 1rem;
    max-width: 50%;
}

.contact-form .text-box {
    border: 1px solid var(--themeColor);
    border-radius: 10px;
    width: 100%;
    height: 2.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.contact-form textarea {
    border: 1px solid var(--themeColor);
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
}

.contact-info {
    background-color: var(--themeColor);
    border-radius: 1.2rem;
    color: white;
    padding: 1rem;
    max-width: 50%;
}

.contact-info > h2 {
    color: white;
}

.contact-form .send-btn {
    color: white;
    background-color: var(--themeColor);
    border: none;
    width: 120px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 5px;
    transition: 0.3s;
}

.contact-form .send-btn:hover {
    background: #000;
}

.location {
    display: flex;
    gap: 1rem;
}

.phone {
    display: flex;
    gap: 1rem;
}

.email {
    display: flex;
    gap: 1rem;
}

@media screen and (min-width: 1600px) {
    .contact-form h2 {
        font-size: 3.8vw;
    }

    .contact-form input {
        font-size: 1.3vw;
    }

    .contact-form span {
        font-size: 0.9vw;
    }

    .contact-info h2 {
        font-size: 3vw;
    }

    .contact-info p {
        font-size: 1.3vw;
    }
}

@media screen and (max-width: 700px) {
    .message {
        flex-direction: column-reverse;
        padding: 1rem;
    }

    .contact-info {
        max-width: 100%;
    }

    .contact-form {
        max-width: 100%;
    }
}