.gallery-body {
    padding: 7vw;
}

.gallery-section {
    background-color: white;
}

.gallery-section {
    display: flex;
    gap: 1rem;
    padding: 1rem;
}

.gallery-section .course-btn {
    color: var(--themeColor);
    background-color: white;
    border: none;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    transition: 0.3s;
}

.gallery-section .course-btn:hover {
    color: white;
    background-color: var(--themeColor);
}

.gallery-container {
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.gallery-row {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
}

.gallery-row img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.gallery-row-img {
    position: relative;
    width: 48%;
}

.gallery-row-img img {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.gallery-row-img .gallery-img-overlay {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.gallery-img-overlay {
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(24, 23, 23, 0.69);
    color: #fff;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.25s;
}

.gallery-img-overlay:hover{
    opacity: 1;
}

.overlay-zoomicon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-zoomicon-btn {
    border: none;
    background:none;
}

.popup {
    position: relative;
}

.popup button {
    position: absolute;
    left: 1%;
    top: 1%;
    border: none;
    background:none;
}

.popup img {
    max-width: 100%;
    max-height: 100%;
}

@media screen and (min-width:1600px) {

    .gallery-section input {
        font-size: 1.4vw;
    }
    
}

@media screen and (max-width: 700px) {
    .gallery-row {
        flex-direction: column;
    }

    .gallery-container {
        gap: 0;
    }
}