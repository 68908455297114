.events {
    background-color: var(--themeColor);
    color: white;
    /* display: flex; */
    padding: 4vw 9vw;
}

.events-container {
    display: flex;
}

.events-img {
    width: 50%;
    display: flex;
    align-items: center;
    /* padding: 1rem; */
}

.events-img img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.events-body {
    width: 50vw;
    padding: 5rem;
}

.events-body h2 {
    font-size: 3.8vw;
}

.events-body h4 {
    color: white;
    font-size: 2.8vw;
}

.events-body p {
    font-size: 1.3vw;
}

.events-body a {
    text-decoration: none;
    color: white;  
    font-size: 1.3vw; 
}

.events-img img {
    width: 100%;
}

@media screen and (max-width:2000px) {
    .events-img img {
        height: 300px;
    }
}

@media screen and (max-width:1000px) {
    .events {
        /* flex-direction: column; */
    }

    .events-img img {
        height: 250px;
        width: 80%;
    }

    .events-container {
        flex-direction: column;
    }

    .events-img {
        width: 100%;
        justify-content: center;
    }
    
    .events-body {
        width: 100%;
        padding: 2rem;
    }

    .events-body h2 {
        font-size: 4.2vw;
    }
    
    .events-body h4 {
        color: white;
        font-size: 3.0vw;
    }
    
    .events-body p {
        font-size: 2.3vw;
    }
    
    .events-body a {
        font-size: 2.3vw; 
    }
    
}

@media screen and (max-width:500px) {
    .events-body h2 {
        font-size: 4.5vw;
    }
    
    .events-body h4 {
        color: white;
        font-size: 3.4vw;
    }
    
    .events-body p {
        font-size: 2.8vw;
    }
    
    .events-body a {
        font-size: 2.8vw; 
    }
}