.inst-search-header {
    background-color: white;
    padding: 0 7vw;
    padding-top: 7vw;
    display: flex;
    justify-content: space-between;
}

.box-container {
    border: 4px solid #008647;
    padding: 0px 10px;
    border-radius: 50px;
}

.elements-container {
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

.search {
    border: none;
    height: 100%;
    width: 100%;
    padding: 0px 5px;
    border-radius: 50px;
}

.search:focus {
    outline: none;
}

@media screen and (min-width:1600px) {

    .search-h2 h2 {
        font-size: 3.8vw;
    } 

    .search {
        font-size: 1.3vw;
    }
    
}

@media screen and (max-width:700px) {
    .inst-search-header {
        flex-direction: column;
    }
}



 