.oppertunities {
    background-color: white;
    padding: 0 7vw;
    padding-bottom: 7vw;
}

.oppertunities h5 {
    padding-bottom: 2vw;
}

.oppertunities-table {
    width: 100%;
}

.oppertunities-table-tr {
    border-bottom: 1pt solid black;
    height: 39px;
    font-size: large;
}

.oppertunities-table-tr th{
    width: 49%;
}

.oppertunities-table-tr td{
    width: 49%;
}


@media screen and (min-width:1600px) {

    .oppertunities p {
        font-size: 1.3vw;
    }

    .oppertunities a {
        font-size: 1.3vw;
    }
    
}

