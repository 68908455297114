.administration-members {
    background-color: white;
    padding: 7vw;
}

.member-container {
    display: flex;
    gap: 2rem;
}

.member-card {
    position: relative;
}

.member-img {
    max-width: 80%;
    height: auto;
}

.member-details {
    background-color: white;
    text-align: center;
    max-width: 80%;
    height: auto;
}

@media screen and (min-width:1600px) {

    .administration-members p {
        font-size: 1.3vw;
    }
    
}

@media screen and (max-width: 500px) {
    .member-container {
        display: flex;
        flex-direction: column;
    }
}