.english-header-img {
    width: 100%;
    height: 50vh;
    position: absolute;
}

.english-header-img img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.english-header-content {
    color: white;
    position: relative;
    height: 50vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 0 7vw;
    justify-content: center;   
}

.english-header-button {
    background-color: white;
    border: none;
    color: #000;
    padding: 15px 32px;
    width: 20vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.3vw;
    margin: 4px 2px;
}

.english-header-content h2 {
    font-size: 2vw;
}

.english-header-content p {
    font-size: 1vw;
}

@media screen and (max-width:900px) {
    .english-header-content h2 {
        font-size: 3vw;
    }
    
    .english-header-content p {
        font-size: 2vw;
    }

    .english-header-button {
        font-size: 2vw;
        width: 40vw;
    }
}