.faculty-members {
    background-color: white;
    padding: 0 7vw;
    padding-bottom: 7vw;
}

.members-section {
    padding: 1rem;
    display: flex;
    gap: 1rem;
}

.members-section .course-btn {
    color: var(--themeColor);
    background-color: white;
    border: none;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    transition: 0.3s;
}

.members-section .course-btn:hover {
    color: white;
    background-color: var(--themeColor);
}

.mentor-container {
    display: flex;
    gap: 2rem;
}

.mentor-card {
    position: relative;
}

.mentor-img {
    max-width: 80%;
    height: auto;
}

.mentor-details {
    background-color: white;
    text-align: center;
    max-width: 80%;
    height: auto;
}

.members-section-hamburger {
    display: none;
}

@media screen and (min-width:1600px) {

    .members-section input {
        font-size: 1.4vw;
    }

    .mentor-details p {
        font-size: 1.3vw;
    }
    
}

@media screen and (max-width: 900px) {
    .members-section {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: auto;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        left: -100%;
        transition: 0.3s;
        z-index: 1;
        padding: 2rem;
    }

    .members-section.active {
        left: 0;
    }

    .members-section-hamburger {
        display: initial;
        /* padding: 1rem; */
        position: relative;
        z-index: 2;
    }

    .mentor-container {
        padding-top: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .mentor-container {
        display: flex;
        flex-direction: column;
    }
}