.admin-institution-list-container {
    width: 75vw;
    height: 64vh;
    position: absolute;
    top: 36vh;
    right: 0;
}

.admin-institution-list {
    padding: 0vw 7vw;
}

.admin-institution-list-table table {
    border-collapse: collapse;
}

.admin-institution-list-table {
    max-height: 56vh;
    overflow-y: scroll;
}

.admin-institution-list-table-row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.admin-institution-list-table-row1 {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-left: 20px;
}

.admin-institution-list-table-row2 {
    display: flex;
    justify-content: space-around;
    width: 100%;
}


.admin-institution-list-table table {
    border-collapse: collapse;
    width: 100%;
    height: 100px;
}

.admin-institution-list-table tr {
    border: 1px solid #dddddd;
}

.admin-list-logo {
    margin-left: 8px;
    cursor: pointer;
    color: black;
}

.admin-list-logo-trash {
    color: red;
    cursor: pointer;
}

@media screen and (min-width:1600px) {
    .admin-list-logo {
        width: 2vw;
        height: auto;
    }

    .admin-list-logo-trash {
        width: 2vw;
        height: auto;
    }

    .admin-institution-list-table-col td {
        font-size: 1.5vw;
    }
}

@media screen and (max-width:900px) {
    .admin-institution-list-container {
        width: 100vw;
    }    
}

@media screen and (max-width:600px) {
    .admin-institution-list-table-row {
        flex-direction: column;
    }

    .admin-institution-list-table-row1 {
        display: flex;
        justify-content: center;
        gap: 0.4rem;
    }

    .admin-institution-list-table-row2 {
        display: flex;
        justify-content: center;
    }

}