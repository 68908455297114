.admin-banner-images-container {
    width: 75vw;
    height: 64vh;
    position: absolute;
    top: 36vh;
    right: 0;
}

.admin-banner-images {
    padding: 0vw 7vw;
    margin: auto;
    width: 75vw;
    height: 64vh;
}

.admin-banner-images img {
    max-width: 100%;
    max-height: 80%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.admin-banner-details {
    display: flex;
    justify-content: center;
    color: #A9A7A7;
    gap: 1rem;
    padding-top: 1rem;
}

.admin-banner-images-logo {
    color: #A9A7A7;
}

.admin-banner-details-eye {
    display: flex;
}

.admin-banner-details-heart {
    display: flex;
}

.admin-banner-images-logo-trash {
    color: red;
    cursor: pointer;
}

@media screen and (max-width:900px) {
    .admin-banner-images-container {
        width: 100vw;
    }    
}
