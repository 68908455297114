.institutions {
  padding: 2rem 5vw;
  display: grid;
  gap: 1.5rem;
  max-width: 1500px;
  margin: auto;
}

.institutions-img {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  height: 400px; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.institutions-img:hover {
  transform: scale(1.02);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}
.institutions-img img {
  width: 100%;
  height: 100%; 
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.institutions-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 1.5rem;
  text-align: center;
}

.institutions-img:hover .institutions-img-overlay {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .institutions {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .institutions {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
  }
}

.institutions-img-overlay h5 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.text-appear {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.contact-info {
  display: flex;
  align-items: start;
  justify-content: start;
  font-size: 0.95rem;
  background-color: transparent;
}

.institutions-img-overlay-icon {
  margin-right: 0.5rem;
  color: white;
}

.contact-text {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.contact-text:hover {
  color: #ddd;
}

@media screen and (max-width: 768px) {
  .institutions-title h2 {
      font-size: 1.8rem;
  }

  .institutions-img-overlay h5 {
      font-size: 1.4rem;
  }

  .text-appear {
      font-size: 0.9rem;
  }

  .contact-text {
      font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .institutions-title h2 {
      font-size: 1.6rem;
  }

  .institutions-img-overlay h5 {
      font-size: 1.3rem;
  }

  .text-appear {
      font-size: 0.85rem;
  }

 

  .contact-text {
      font-size: 0.85rem;
  }
}
