.heroV2 {
  background-color: antiquewhite;
  width: 100%;
  height: 100vh;
}

.heroV2-header {
  background-color: var(--themeColor);
  color: #fff;
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  padding: 20px 7vw;
  font-size: 1vw;
}

.white-header{
    width: 100%;
    height: 12vh;
  
}

/* .heroV2-header p {
    font-size: 40px;
} */

.heroV2-header-logo{
  display: flex;
  gap: 0.5em;
  cursor: pointer;
}

.heroV2-header-a {
  text-decoration: none;
  color: #fff;
}

.heroV2-header-a:hover {
  color: rgb(72, 131, 186);
}

.heroV2-header-button {
  background-color: #fff;
  border: none;
  border-radius: 8px;
  color: var(--themeColor);
  font-size: 13px;
  height: 30px;
  width: 110px;
}

.heroV2-img {
  background-color: rgb(205, 156, 156);
  width: 100%;
  height: 86vh;
  position: relative;
}

.heroV2-img img {
  width: 100%;
  height: 86vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.heroV2-img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(13, 13, 13, 0.63);
  opacity: 0.8;
}

.heroV2-title {
  position: absolute;
  top: 25vh;
  left: 10%;
  color: white;
  max-width: 100%;
}

.heroV2-title h5 {
  font-size: 2.1rem;
  font-weight: 400;
}

.heroV2-title {
  max-width: 100%; 
}

.heroV2-title h2 {
  font-size: 4rem;
  font-weight: 700;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto; 
}

@media (max-width: 992px) {
  .heroV2-title h2 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .heroV2-title h2 {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .heroV2-title h2 {
    font-size: 1.5rem;
  }
}

.heroV2-title-btn {
  background-color: var(--themeColor);
  border: none;
  color: white;
  padding: 11px 29px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin: 4px 2px;
}

.heroV2-title-btn a {
  text-decoration: none;
  color: #fff;
}

.heroV2-course-slider {
  position: absolute;
  top: 60vh;
  left: 10%;
  background-color: var(--themeColor);
  width: 80vw;
  height: 32vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
  padding: 1rem 3rem;
}

.slick-slide {
  padding: 0.6rem;
}

.heroV2-course-slider h5 {
  font-size: 2.1rem;
  font-weight: 400;
}

.heroV2-course-slider .check {
  width: 60vw;
}

.heroV2-course-slider .slider {
  width: 60vw;
}

.heroV2-course {
  background: rgba(69, 107, 149, 0.65);
  border-radius: 20px;
  width: 17vw;
  height: 20vh;
  text-align: center;
  padding: 2rem;
}

.heroV2-course-btn {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 0.6rem;
}

.heroV2-course-btn button {
  background-color: #2b4968;
  border: none;
  color: white;
  padding: 5px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  border-radius: 10px;
}

header {
  height: 7vh;
  background-color: white;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0 7vw;
}

header img {
  max-width: 100%;
  max-height: 7vh;
}

.nav-toggle {
  display: none;
}

.nav-toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  background: black;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: "";
  position: absolute;
}

.nav-toggle-label span::before {
  bottom: 7px;
}

.nav-toggle-label span::after {
  top: 7px;
}

nav {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: white;
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav li {
  margin-bottom: 1em;
  margin-left: 1em;
}

nav a {
  color: var(--themeColor);
  text-decoration: none;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.nav-toggle:checked ~ nav {
  transform: scale(1, 1);
}

.nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 900px) {
  .nav-toggle-label {
    display: none;
  }

  header {
    display: grid;
    grid-template-columns: 1fr auto minmax(100px, 4fr) 1fr;
  }

  header img {
    min-width: 190px;
    max-height: 7vh;
  }

  .logo {
    grid-column: 1 ;
  }

  nav {
    all: unset;
    grid-column: 3 / -1;
    margin-right: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  nav ul {
    display: flex;
    justify-content: flex-end;
  }

  nav li {
    margin-left: 3em;
    margin-bottom: 0;
  }

  nav a {
    opacity: 1;
    font-size: 1.3vw;
  }
}

@media screen and (max-width: 1800px) {
  .heroV2-header p {
    font-size: 1.1vw;
  }

  .heroV2-course-slider .slider {
    width: 60vw;
  }
}

@media screen and (max-width: 1200px) {
  .heroV2-header p {
    font-size: 1.3vw;
  }

  .heroV2-course-slider .slider {
    width: 50vw;
  }
}

@media screen and (max-width: 900px) {
  .heroV2-header {
    display: none;
  }

  .heroV2-img {
    height: 93vh;
  }

  .heroV2-img img {
    height: 93vh;
  }

  .heroV2-course-slider .slider {
    width: 40vw;
  }
}

@media screen and (max-width: 600px) {
  .heroV2-course-slider {
    flex-direction: column;
    gap: 0;
  }

  .heroV2-course-slider .slider {
    width: 60vw;
  }
}

@media screen and (max-width: 420px) {
  .heroV2-course-slider {
    top: 63vh;
    width: 80vw;
    height: 30vh;
    gap: 0rem;
    padding: 1rem 3rem;
  }

  .heroV2-title h5 {
    font-size: 1.8rem;
    font-weight: 400;
  }

  .heroV2-title h2 {
    font-weight: 700;
  }

  .heroV2-title-btn {
    padding: 6px 29px;
    font-size: 1em;
    margin: 0px 2px;
  }

  .heroV2-course {
    width: 17vw;
    height: 18vh;
    text-align: center;
    padding: 0.5rem;
  }

  .heroV2-course-btn button {
    padding: 5px 22px;
    font-size: 0.6em;
  }

  .heroV2-course b {
    font-weight: 700;
    font-size: 14px;
    line-height: 35px;
  }

  .heroV2-course-slider h5 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

@media screen and (max-height: 650px) {
  .heroV2-title-btn {
    display: none;
  }
}
