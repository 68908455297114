.institution-list {
    background-color: white;
    padding: 7vw 7vw;
}

.institution-list-header {
    background-color: var(--themeColor);
    color: white;
    padding: 1rem;
}

.institution-list-body {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.institution-list-img {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: antiquewhite;
    overflow: hidden;
}

.institution-list-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    text-align: center;
}

.overlay h4, .overlay p {
    margin: 0;
    padding: 0.5rem;
}

/* Responsive adjustments */
@media screen and (min-width:1600px) {
    .institution-list-header h2 {
        font-size: 3vw;
    }

    .overlay p {
        font-size: 1.1vw;
    }

    .overlay h4 {
        font-size: 1.8vw;
    }
}

@media screen and (max-width: 700px) {
    .institution-list-body {
        flex-direction: column;
    }

    .institution-list-img {
        width: 100%;
        height: 250px;
    }
}
