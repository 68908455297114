.admission-body {
    background-color: #ECECEC;
    padding: 7vw;
    display: flex;
    flex-direction: column;
}

.admission-body-top {
    display: flex;
    padding: 1rem;
}

.adm-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

}

.lab-button {
    max-width: fit-content;
}

.adm-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.adm-img img{
    max-width: 100%;
    height: 45vh;
}

.admission-body-bottom {
    display: flex;
    justify-content: space-around;
    padding-top: 3rem;
}

.bot-col {
    max-width: 20%;
}

@media screen and (min-width:1600px) {

    .admission-body p {
        font-size: 1.3vw;
    }

    .admission-body h5 {
        font-size: 2.3vw;
    }
    
}

@media screen and (max-width:1000px) {
    .admission-body-top {
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }

    .adm-content {
        width: 100%;
        align-items: center;
    }

    .adm-img {
        width: 100%;
    }

    .admission-body-bottom {
        flex-direction: column;
        align-items: center;
    }

    .bot-col {
        max-width: 70%;
    }
}
