.login-mask {
    width: 100%;
    height: 100vh;
    position: absolute;
}

.login-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.login-mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(16, 16, 16, 0.5);
    opacity: 0.8;
}

.login-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;   
}

.login-content {
    padding: 5vw;
    background: rgba(14, 14, 13, 0.49);
    box-shadow: 0px 0px 4px 6px rgba(111, 111, 111, 0.25);
    border-radius: 15px;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
}

.login-content-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.login-content h2 {
    font-weight: 700;
    font-size: 5vw;
}

.login-content h4 {
    font-weight: 400;
    font-size: 3vw;
}

.login-form-input {
    background: rgba(255, 255, 255, 0.68);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 20vw;
    padding: 12px 20px;
    padding-left: 2.3rem;
}

.login-form-div {
    position: relative;
}

.login-form-icon {
    position: absolute;
    top: 0.8rem;
    left: 0.5rem
}

.login-form-button {
    background-color: var(--themeColor);
    border: none;
    color: white;
    padding: 12px 20px;
    border-radius: 10px;
    width: 20vw;

}

.login-content a {
    text-decoration: none;
    color: #fff;
}

@media screen and (min-width:1600px) {
    .login-form-input {
        font-size: 1vw;
        padding-left: 4rem;
    }

    .login-form-button {
        font-size: 1vw;
    }

    .login-form-icon {
        width: 1.7vw;
        height: auto;
    }

    .login-content a {
        font-size: 1vw;
    }
}

@media screen and (max-width:700px) {
    .login-content h2 {
        font-weight: 700;
        font-size: 7vw;
    }

    .login-form-input {
        width: 45vw;
    }

    .login-form-button {
        width: 45vw;
    }

    .login-content {
        padding: 8vw;
    }
}