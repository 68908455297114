.footer {
    background-color: var(--themeColor);
    color: white;
    display: flex;
    padding: 1rem 9vw;
    padding-top: 1rem;
    padding-bottom: -1rem;
}

.footer-body {
    display: flex;
    flex-direction: column;
    width: 33%;
}

.footer-body img {
    max-width: 80%;
}

.footer-links {
    width: 33%;
    padding-left: 1rem;
}

.links-li {
    display: flex;
}

.links-li ul {
    padding-top: 0rem;
}

.footer-contact {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contact-phone {
    display: flex;
    gap: 1rem;
}

.contact-phone-a {
    display: flex;
    flex-direction: column;
}

.contact-phone p {
    padding-top: 0rem;
}

.contact-email{
    display: flex;
    gap: 1rem;
}

.contact-address {
    display: flex;
    gap: 1rem;
}

.footer h4 {
    color: white;
}

.links-li a {
    text-decoration: none;
    color: white;
    margin-left: -1.6rem;
}

.links-li li {
    list-style-type: none;
}

@media screen and (max-width:900px) {
    .footer {
        flex-direction: column;
        padding: 1rem 9vw;
    }

    .footer-body {
        width: 100%;
    }

    .footer-links {
        width: 100%;
    }

    .footer-contact {
        width: 100%;
    }

    .footer-body img {
        max-width: 70%;
    }

}

@media screen and (max-width:400px) {
    .footer {
        padding: 0rem 2rem;
    }

    .footer-body img {
        max-width: 100%;
    }
}

@media screen and (min-width:1200px) {
    .footer {
        padding: 1rem 9vw;
    }
    
    .footer li a {
        font-size: 1.1vw;
    }

    .footer h4 {
        font-size: 1.4vw;
    }

    .footer p {
        font-size: 0.9vw;
    }

    .anchor {
        font-size: 0.9vw;
    }
}

